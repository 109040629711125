@font-face
  font-family: 'Inter'
  src: url('../fonts/Inter.ttf')

@import './freya/layout/layout.scss'
@import "./variables.sass"

a
  color: $base-text-color

  &:hover
    color: $base-hover-color

.d-grid
  display: grid

.grid-c-50-50
  grid-template-columns: 50% 50%

.place-content-center
  place-content: center

.grid-fit-400
  grid-template-columns: repeat(auto-fit, minmax(1px, 400px))

.input-small
  max-width: 5rem

.favourite-button
  display: flex
  justify-content: center
  align-items: center
  width: 30px
  height: 30px
  cursor: pointer
  margin-left: 5px
  border: 1px solid $base-text-color
  border-radius: 30px

  &:hover
    border: 1px solid $base-hover-color
    .pi
      color: $base-hover-color

      &:before
        content: "\e9e1"

.context-menu-button
  display: flex
  justify-content: center
  align-items: center
  width: 30px
  height: 30px
  cursor: pointer
  margin-left: 5px
  border: 1px solid $base-text-color
  border-radius: 30px

  &:hover
    border: 1px solid $base-hover-color

    .pi
      color: $base-hover-color

.float-right
  float: right

.margin-auto
  margin: auto

.p-contextmenu
  width: auto

.p-dialog
  min-width: 30%

.p-accordion .p-accordion-header .p-accordion-header-link
  padding: 0.5rem

.p-accordion-tab
  -webkit-transition: width 0.4s ease-in-out
  -moz-transition: width 0.4s ease-in-out
  -o-transition: width 0.4s ease-in-out
  transition: width 0.4s ease-in-out
  width: 13rem

.p-accordion-tab-active
    -webkit-transition: width 0.4s ease-in-out
    -moz-transition: width 0.4s ease-in-out
    -o-transition: width 0.4s ease-in-out
    transition: width 0.4s ease-in-out
    width: 100%


.p-button
  border-radius: 12px


.paginator-component
  .p-paginator
    border: 0px
    background-color: $base-background-color

.pi-exclamation-triangle
  color: $base-hover-color
  &:before
    font-size: 2rem



.font-style-normal
  font-style: normal

.base-hover-color
  color: $base-hover-color
